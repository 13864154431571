var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-media', {
    attrs: {
      "tag": "li"
    },
    scopedSlots: _vm._u([{
      key: "aside",
      fn: function fn() {
        return [_c('div', {
          staticClass: "img-box"
        }, [_c('b-img', {
          attrs: {
            "src": _vm.itemData.imageLink || _vm.itemData.custom.shopImage,
            "width": "50",
            "height": "50",
            "alt": "placeholder"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "d-flex justify-content-between item-box"
  }, [_c('div', {
    staticClass: "item-qty ml-3"
  }, [_c('h6', {
    staticClass: "mt-0 mb-3 text-left"
  }, [_vm._v(" " + _vm._s(_vm.itemData.name || _vm.itemData.providerName) + " ")]), _c('h6', [_c('span', {
    staticClass: "mr-2"
  }, [_vm._v("Quantity:")]), _vm._v("500gm")])]), _c('div', {
    staticClass: "price-details"
  }, [_c('div', {
    staticClass: "d-flex deal-price align-items-center mb-3"
  }, [_c('h6', [_vm._v("₹ " + _vm._s(_vm.itemData.price))]), _vm.itemData.price !== _vm.itemData.unitPrice && _vm.itemData.unitPrice > 0 ? _c('del', {
    staticClass: "mdi mdi-currency-inr text-icon mb-0 ml-3"
  }, [_vm._v(_vm._s(_vm.itemData.unitPrice))]) : _vm._e()]), _vm.itemData.discount ? _c('h6', {
    staticClass: "discount-rate"
  }, [_vm._v(" ₹ " + _vm._s(_vm.itemData.discount.toFixed(2)) + " ")]) : _c('h6', {
    staticClass: "discount-rate"
  }, [_vm._v("₹ " + _vm._s(_vm.itemData.discount) + "%")])]), _c('p', {
    staticClass: "text-top"
  }, [_vm._v(_vm._s(_vm.itemData.qty))]), _c('p', [_vm._v(_vm._s(_vm.itemData.price))])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }