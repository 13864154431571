<template>
  <b-media tag="li">
    <template #aside>
      <div class="img-box">
        <b-img
          :src="itemData.imageLink || itemData.custom.shopImage"
          width="50"
          height="50"
          alt="placeholder"
        ></b-img>
      </div>
    </template>
    <div class="d-flex justify-content-between item-box">
      <div class="item-qty ml-3">
        <h6 class="mt-0 mb-3 text-left">
          {{ itemData.name || itemData.providerName }}
        </h6>
        <h6><span class="mr-2">Quantity:</span>500gm</h6>
      </div>

      <div class="price-details">
        <div class="d-flex deal-price align-items-center mb-3">
          <h6>₹ {{ itemData.price }}</h6>
          <del
            class="mdi mdi-currency-inr text-icon mb-0 ml-3"
            v-if="
              itemData.price !== itemData.unitPrice && itemData.unitPrice > 0
            "
            >{{ itemData.unitPrice }}</del
          >
        </div>
        <h6 class="discount-rate" v-if="itemData.discount">
          ₹ {{ itemData.discount.toFixed(2) }}
        </h6>
        <h6 class="discount-rate" v-else>₹ {{ itemData.discount }}%</h6>
      </div>
      <p class="text-top">{{ itemData.qty }}</p>
      <p>{{ itemData.price }}</p>
    </div>
  </b-media>
</template>
<script>
export default {
  name: "order-details-item",
  props: {
    itemData: {
      default: () => {},
      type: Object,
    },
  },
};
</script>

<style scoped>
.img-box {
  border: 1px solid #efefef;
  border-radius: 5px;
  padding: 4px;
}
.deal-price h6 {
  margin-bottom: 0px;
  color: #3a38c5;
}
.deal-price del {
  color: #949494;
}
.discount-rate {
  margin-top: 0px;
  color: #ff7a7a;
}
.item-qty span {
  color: #aaaaaa;
}
h6,
p {
  margin: 0px;
}
</style>
